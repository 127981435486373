import axios from "axios";
axios.defaults.withCredentials = true;

const BASE_URL = "https://sleep-3bc107e1b16b.herokuapp.com/";


const predict = async (inputData, setPrediction, setIsLoading) => {
  try {
    const response = await axios.create().post(`${BASE_URL}ml/predict/`,inputData);

    if (response.data.success) {
        setPrediction(response.data.prediction);
        console.log(response.data)
    }
    if (!response.data.success) {
      console.log("couldn't make the prediction");
    }
  } catch (err) {
    console.log(err);
    console.log(err.message);
  } finally {
    setIsLoading(false);
  }
};

const MlAPI = {
    predict,
};

export default MlAPI;