import React from "react";
import styled from "styled-components";

const GetPredictionForm = ({ formData, handleChange, handleSubmit }) => {
  return (

    <FormContainer>

    
    <form onSubmit={handleSubmit} className="predictionForm">
      {/* Age */}
      <label htmlFor="age">Age</label>
      <input
        type="number"
        id="age"
        name="age"
        value={formData.age}
        onChange={handleChange}
        className="formInput"
      />

      <label htmlFor="sleep_duration">Sleep Duration</label>
      <select
        id="sleep_duration"
        name="sleep_duration"
        value={formData.sleep_duration}
        onChange={handleChange}
        className="formInput select"
      >
        <option value="4">4 hours</option>
        <option value="5">5 hours</option>
        <option value="6">6 hours</option>
        <option value="7">7 hours</option>
        <option value="8">8 hours</option>
        <option value="9">9 hours</option>
        <option value="10">10+ hours</option>
      </select>

      {/* Sleep Quality */}
      <label htmlFor="sleep_quality">Sleep Quality</label>
      <input
        type="number"
        id="sleep_quality"
        name="sleep_quality"
        value={formData.sleep_quality}
        onChange={handleChange}
        className="formInput"
      />

      {/* Activity Level */}
      <label htmlFor="activity_level">Activity Level</label>
      <input
        type="number"
        id="activity_level"
        name="activity_level"
        value={formData.activity_level}
        onChange={handleChange}
        className="formInput"
      />

      {/* Stress Level */}
      <label htmlFor="stress_level">Stress Level</label>
      <input
        type="number"
        id="stress_level"
        name="stress_level"
        value={formData.stress_level}
        onChange={handleChange}
        className="formInput"
      />

      {/* Heart Rate */}
      <label htmlFor="heart_rate">Heart Rate</label>
      <input
        type="number"
        id="heart_rate"
        name="heart_rate"
        value={formData.heart_rate}
        onChange={handleChange}
        className="formInput"
      />

      {/* Daily Steps */}
      <label htmlFor="daily_steps">Daily Steps</label>
      <input
        type="number"
        id="daily_steps"
        name="daily_steps"
        value={formData.daily_steps}
        onChange={handleChange}
        className="formInput"
      />
  
      {/* Diastolic BP */}
      <label htmlFor="diastolic_bp">Diastolic BP</label>
      <input
        type="number"
        id="diastolic_bp"
        name="diastolic_bp"
        value={formData.diastolic_bp}
        onChange={handleChange}
        className="formInput"
      />

      {/* Systolic BP */}
      <label htmlFor="systolic_bp">Systolic BP</label>
      <input
        type="number"
        id="systolic_bp"
        name="systolic_bp"
        value={formData.systolic_bp}
        onChange={handleChange}
        className="formInput"
      />

      {/* Gender */}
      <label htmlFor="gender">Gender</label>
      <select
        id="gender"
        name="gender"
        value={formData.gender}
        onChange={handleChange}
        className="formInput select"
      >
        <option value="Male">Male</option>
        <option value="Female">Female</option>
      </select>

      {/* BMI */}
      <label htmlFor="bmi">BMI</label>
      <select
        id="bmi"
        name="bmi"
        value={formData.bmi}
        onChange={handleChange}
        className="formInput select"
      >
        <option value="Normal">Normal</option>
        <option value="Overweight">Overweight</option>
        <option value="Obese">Obese</option>
      </select>

      {/* Submit button */}
      
    </form>
      <button className="predictionButton" onClick={handleSubmit}>Confirm</button>
    
    </FormContainer>
  );
};

export default GetPredictionForm;

const FormContainer = styled.div`

.predictionForm{
  display: grid;
  grid-template-columns: 1fr 3fr; /* Two equal columns */
  justify-content: center;
  align-items: flex-start;
  margin: 0 20px;
  flex-direction: column;
  padding-top: 20px;
  overflow: hidden;
  gap:20px;
  
}

.doubleColumns{
  display:flex;
}

.formInput{
  border-radius:5px;
  min-height:25px;
  background-color: #fcf7f3;
  border: 2px solid black;
  margin: 0 20px;
  padding-left:7px;
}

.select{
  min-height:35px;
  margin: 0 20px;
  padding: 0 7px;
}


.predictionButton{
  display:flex;
  align-items:center;
  justify-content:center;
  background-color: #f55283;
  color:white;
  margin:10px auto;
  border: 1px solid #f55283;
  border-radius:7px;
  padding:10px;
  min-width:90vw;
  margin-top:30px;
  font-size:20px;
}
`;