import React, {useState } from "react";
import styled from "styled-components";
import MlAPI from "../../api/ml.api";
import GetPredictionForm from "../../components/getPredictionForm";

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [prediction, setPrediction] = useState([]);
  
  const [formData, setFormData] = useState({
    age: "",
    sleep_duration: "",
    sleep_quality: "",
    activity_level: "",
    stress_level: "",
    bmi: "",
    heart_rate: "",
    daily_steps: "",
    diastolic_bp: "",
    systolic_bp: "",
    gender: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    MlAPI.predict(formData, setPrediction, setIsLoading);
  };

  return (
    <HomeContainer>
      <GetPredictionForm
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
      {isLoading ? <div></div> : <h1>Prediction: {prediction}</h1>}
    </HomeContainer>
  );
};

export default Home;

const HomeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  margin: 0;
  flex-direction: column;
  padding-top: 20px;
`;