import React from "react";
import styled from "styled-components";

const Header = () => {
  return (
    <HeaderContainer>
        <h1>
            Sleepin.
        </h1>
    </HeaderContainer>
  );
};

export default Header;
const HeaderContainer = styled.div`
  display: flex;
  position: sticky;
  width: 100vw;
  top: 0;
  height: 60px;
  left: 0;
  background-color: #f55283;
  z-index: 1000;
  align-items: center;
  justify-content: center;
  /* border: 2px solid black; */

  .logo {
    width: 100px;
    margin: 4px auto;
  }
`;