import React from "react";
import styled from "styled-components";


const Footer = () => {
return (
<FooterContainer>
    <a className="footerNote" href="https://analysewithrishi.carrd.co">Made with love by Rishi</a>
</FooterContainer>


);
};


export default Footer


const FooterContainer = styled.div`

display:flex;
margin:0 auto;
align-items: center;
justify-content: center;
padding:5px 0;



.footerNote{
    display:flex
    margin:0 auto;
    color:black;
}

`;